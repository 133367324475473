.NavDrawerButton {
    background-color: transparent;
    border: 0px;
    margin-bottom: var(--spacing-2x);
    margin-right: calc(-1 * var(--spacing-3x));
    outline: none;
}

.NavDrawerButton .drag-handle {
    border: 0px;
    border-radius: var(--spacing-1x);
    background-color: var(--light-grey2-color);
    height: 6px;
    margin-bottom: var(--spacing-4x);
    outline: none;
    width: 60px;
}

.NavDrawerButton .drawer {
    background-color: var(--off-white-color);
    padding: var(--spacing-4x) var(--spacing-6x) var(--spacing-10x);
}

.NavDrawerButton .drawer nav {
    background-color: var(--light-grey5-color);
    border-radius: var(--spacing-2x);
    border: 1px solid var(--light-grey3-color);
    height: calc(100% - var(--spacing-4x));
}

.NavDrawerButton .drawer nav ul {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    list-style-type: none;
}

.NavDrawerButton .drawer nav ul li {
    align-items: center;
    border-bottom: 1px solid var(--light-grey2-color);
    display: flex;
    flex-basis: 25%;
    justify-content: flex-start;
    padding-left: var(--spacing-4x);
    position: relative;
    width: 100%;
}
/* 
.NavDrawerButton .drawer nav ul li:last-child {
    border-bottom: 0px;
} */

.NavDrawerButton .drawer nav ul li a {
    align-items: center;
    display: flex;
    height: 100%;
    font-size: var(--font-size-regular);
    font-weight: 600;
    justify-content: flex-start;
    width: 100%;
}

.NavDrawerButton .drawer nav ul li a {
    align-items: center;
    display: flex;
    height: 100%;
    font-size: var(--font-size-regular);
    font-weight: 600;
    justify-content: space-between;
    width: 100%;
}

.NavDrawerButton .drawer nav ul li a svg {
    fill: var(--grey4-color);
    font-size: var(--font-size-regular);
    font-weight: 600;
    margin-right: var(--spacing-4x);
}

.NavDrawerButton .drawer nav ul li svg {
    fill: var(--black-color);
    font-size: var(--font-size-regular);
    font-weight: 600;
}