.PageHeader {
    background-color: var(--off-white-color);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    height: calc(20px + var(--standard-banner-height));
    min-height: var(--standard-banner-height);
    left: 0;
    width: 100vw;
}

.PageHeader .container {
    border-bottom: 3px solid var(--off-black-color);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.PageHeader .container nav a {
    cursor: pointer;
}

.PageHeader .container .titles {
    margin-bottom: var(--spacing-3x);
    margin-left: 0;
    text-decoration: none;
}

.PageHeader .container .titles:hover {
    color: var(--black-color);
}

.PageHeader .title {
    font-size: var(--font-size-regular);
    font-weight: 700;
    line-height: 20px;
    /* margin-bottom: var(--spacing-3x); */
}

.PageHeader .subtitle {
    font-size: var(--font-size-small);
    font-weight: 400;
    /* line-height: 18px; */
    margin-left: var(--spacing-2x);
    /* margin-bottom: var(--spacing-3x); */
}

.PageHeader .mobile-content-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.PageHeader .desktop-content-container {
    align-items: baseline;
    display: none;
    justify-content: space-between;
    width: 100%;
}

.PageHeader .mobile-content-container .subtitle {
    margin-left: 0;
}

.PageHeader .mobile-content-container .titles {
    flex-direction: column;
}


.PageHeader .mobile-content-container nav ul,
.PageHeader .desktop-content-container nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.PageHeader .mobile-content-container nav ul {
    margin-bottom: var(--spacing-4x);
}

.PageHeader .mobile-content-container .btn:not(:last-child) {
    margin-right: var(--spacing-4x);
}

.PageHeader nav a {
    font-size: var(--font-size-small);
    font-weight: 400;
    margin-left: var(--spacing-3x);
    text-decoration: none;
}

.PageHeader .dotvider {
    margin-left: var(--spacing-3x);
    font-size: 24px;
    max-height: 20px;
    line-height: 24px;
}

@media only screen and (min-width: 640px) {
    .PageHeader .mobile-content-container {
        display: none;
    }
    
    .PageHeader .desktop-content-container {
        display: flex;
    }
}