.ContactPage {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ContactPage .content {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.ContactPage .by-email,
.ContactPage .elsewhere {
  flex-basis: 50%;
}

.ContactPage .by-email {
  justify-content: flex-start;
  padding-right: 0;
  margin-bottom: var(--spacing-16x);
}

.ContactPage .elsewhere {
  justify-content: flex-start;
  padding-left: 0;
  margin-bottom: var(--spacing-16x);
}

.ContactPage .contact-header {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.ContactPage .contact-callout {
  background-color: var(--light-grey3-color);
  display: flex;
  font-size: var(--font-size-xsmall);
  font-weight: 500;
  justify-content: center;
  padding: var(--spacing-4x);
  margin-top: var(--spacing-5x);
  width: 100%;
}

.ContactPage .contact-callout .Row {
  align-items: center;
  margin-top: var(--spacing-3x);
  margin-bottom: var(--spacing-3x);
}

.ContactPage .contact-callout .Row .Label {
  color: var(--grey1-color);
}

.ContactPage .twitter,
.ContactPage .instagram,
.ContactPage .linkedin {
  color: var(--grey2-color);
  margin-left: var(--spacing-3x);
  margin-right: var(--spacing-5x);
  transition: all 100ms ease-in-out;
}

.ContactPage .twitter:hover,
.ContactPage .instagram:hover,
.ContactPage .linkedin:hover {
  color: var(--grey4-color);
}

.ContactPage .Label a,
.ContactPage .Label a,
.ContactPage .Label a {
  text-decoration-style: dotted;
}

@media only screen and (min-width: 740px) {
  .ContactPage .content {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .ContactPage .by-email {
    justify-content: flex-start;
    padding-right: var(--spacing-5x);
    margin-bottom: 0;
  }
  
  .ContactPage .elsewhere {
    justify-content: flex-start;
    padding-left: var(--spacing-5x);
    margin-bottom: 0;
  }
}
