.MaxWidthContainer {
  max-width: var(--mobile-max-page-width);
  width: 100%;
}

@media only screen and (min-width: 980px) {
  .MaxWidthContainer {
    max-width: var(--max-page-width);
  }
}
