/** @format */

.HomePage {
	align-items: center;
	display: flex;
	flex-direction: column;
}

.HomePage .banner-container {
	background-color: var(--light-grey3-color);
	margin-top: var(--spacing-4x);
	height: calc(100vw - var(--spacing-10x));
	aspect-ratio: 1 / 1;
	position: relative;
}

.HomePage .banner {
	object-fit: cover;
	height: 100%;
}

.HomePage .about-header {
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 3px;
}

.HomePage .about-label {
	max-width: 100%;
}

@media only screen and (min-width: 640px) {
	.HomePage .banner-container {
		aspect-ratio: 16/ 9;
		height: auto;
	}

	.HomePage .about-label {
		max-width: 50%;
	}
}

@media only screen and (min-width: 980px) {
	.HomePage .banner-container {
		background-color: var(--light-grey3-color);
		margin-top: var(--spacing-2x);
		height: auto;
	}
}
