.ContactForm {
  width: 100%;
}

.ContactForm .input-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-bottom: var(--spacing-5x);
  width: 100%;
}

.ContactForm .input-container.message {
    padding-bottom: 0;
  }

.ContactForm .input-container.column {
  align-items: flex-start;
  align-content: space-between;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ContactForm .input-container.column label {
  font-size: var(--font-size-regular);
  font-weight: 500;
  width: 105px;
}

.ContactForm .input-container.row label {
  height: 24px;
  font-size: var(--font-size-regular);
  font-weight: 500;
  flex-shrink: 1;
  margin-right: var(--spacing-4x);
  width: 105px;
}

.ContactForm .input-container.row input {
  height: 24px;
  flex-grow: 1;
  font-size: var(--font-size-regular);
}

.ContactForm .input-container .message {
  margin-bottom: var(--spacing-2x);
}

.ContactForm .input-container textarea {
  font-size: var(--font-size-regular);
  font-weight: 500;
  min-height: 240px;
  resize: none;
  width: 100%;
}

.ContactForm .btn.send {
  align-items: center;
  background-color: var(--black-color);
  border: 0px;
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-3x);
  outline: none;
  padding: var(--spacing-1x);
  padding-left: var(--spacing-3x);
  padding-right: var(--spacing-4x);
  height: 40px;
  width: 100px;
}

.ContactForm .btn.send:hover {
  background-color: var(--grey1-color);
}

.ContactForm .btn.send .Label {
  font-size: var(--font-size-small);
  margin-left: var(--spacing-1x);
}

@media only screen and (min-width: 740px) {
  .ContactForm .btn.send {
    height: 30px;
    margin-top: var(--spacing-2x);
    width: 82px;
  }

  .ContactForm .input-container.row label {
    flex-shrink: 1;
  }

  .ContactForm .input-container.row input {
    flex-grow: 1;
  }

  .ContactForm .input-container.column label {
    width: 120px;
  }

  .ContactForm .input-container.row label {
    width: 120px;
  }
}
