/* Imports */

/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");

/* Vars */

:root {
  /* --blue: #3a5cc2; */
  --blue: #336dd8;
  --blue-dark-tint: #2957ad;
  --blue-light-tint: #5c8ae0;
  --primary-foreground-color: #ffffff;
  --primary-background-color: #ffffff;
  --btn-primary-text-color: #ffffff;
  --btn-secondary-text-color: #111418;
  --btn-primary-background-color: #111418;
  --btn-primary-background-color-hover: #252a31;
  --btn-secondary-background-color: #ffffff;
  --btn-secondary-background-color-hover: #f6f7f9;
  --primary-text-color: #111418;
  --primary-button-color: #111418;
  --pink-color: pink;
  --green-color: #00b777;
  --dark-green-color: #00b777;
  --blue-color: #3a5cc2;
  --light-blue-color: #3a5cc2;
  --white-color: #ffffff;
  --off-white-color: #fefbf8;
  --black-color: #111418;
  --off-black-color: #181c21;
  --grey1-color: #5f6b7c;
  --grey2-color: #738091;
  --grey3-color: #8f99a8;
  --grey4-color: #abb3bf;
  --grey5-color: #c5cbd3;
  --light-grey1-color: #d3d8de;
  --light-grey2-color: #dce0e5;
  --light-grey3-color: #e5e8eb;
  --light-grey4-color: #edeff2;
  --light-grey5-color: rgb(250, 250, 250);
  --font-size-xxlarge: 70px;
  --font-size-xlarge: 52px;
  --font-size-large: 30px;
  --font-size-medium: 20px;
  --font-size-regular: 15px;
  --font-size-small: 14px;
  --font-size-body: 16px;
  --font-size-xsmall: 12px;
  --spacing-1x: 4px;
  --spacing-2x: 8px;
  --spacing-3x: 12px;
  --spacing-4x: 16px;
  --spacing-5x: 20px;
  --spacing-6x: 24px;
  --spacing-7x: 28px;
  --spacing-8x: 32px;
  --spacing-9x: 36px;
  --spacing-10x: 40px;
  --spacing-12x: 48px;
  --spacing-13x: 52px;
  --spacing-14x: 56px;
  --spacing-16x: 64px;
  --spacing-18x: 72px;
  --spacing-20x: 80px;
  --spacing-30x: 120px;
  --max-page-width: 940px;
  --mobile-max-page-width: calc(100vw - var(--spacing-10x));
  --standard-container-padding: var(--spacing-6x);
  --large-banner-height: var(--spacing-20x);
  --standard-banner-height: var(--spacing-13x);
  --small-banner-height: var(--spacing-10x);
  --min-button-height: 34px;
  /* Toastify */
  --toastify-color-success: #00b777;
}

/* Clear styles */

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
h1,
h2,
h3 {
  font-weight: bold;
}

h1 {
  font-size: var(--font-size-large);
}

h2 {
  font-size: var(--font-size-medium);
}

h3 {
  font-size: var(--font-size-regular);
}

h4 {
  font-size: var(--font-size-regular);
}

h5 {
  font-size: var(--font-size-regular);
}

p,
h6 {
  font-size: var(--font-size-small);
}

input,
textarea,
button,
select,
.NavDrawerButton,
a {
    -webkit-tap-highlight-color: transparent;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* Etc */

body {
  background-color: var(--off-white-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* General */

a,
a:focus,
a:visited {
  color: inherit;
  transition: color 100ms ease-in;
}

a:hover {
  color: var(--grey3-color);
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
