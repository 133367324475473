/* Toastify */

.Toastify__toast {
  box-shadow: none;
  border-radius: 0;
  border: 1px solid var(--light-grey2-color);
}

.Toastify__progress-bar {
  height: 3px;
}