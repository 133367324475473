.Footer {
    align-items: center;
    background-color: var(--off-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: var(--standard-banner-height);
    width: 100vw;
}

.Footer .container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer .copyright {
    color: var(--grey3-color);
    font-size: var(--font-size-xsmall);
}

.Footer .twitter .link-primary {
    color: var(--black-color);
    font-size: var(--font-size-small);
}