.PageSubHeader {
    background-color: var(--off-white-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: var(--standard-banner-height);
    min-height: var(--standard-banner-height);
    left: 0;
    width: 100%;
}

.PageSubHeader .container {
    border-bottom: 1px solid var(--grey5-color);
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.PageSubHeader .mobile-buttons-container {
    display: flex;
}

.PageSubHeader .desktop-buttons-container {
    display: none;
}

.PageSubHeader .mobile-buttons-container .btn:not(:last-child) {
    margin-right: var(--spacing-4x);
}

@media only screen and (min-width: 480px) {
    .PageSubHeader .mobile-buttons-container {
        display: none;
    }
    
    .PageSubHeader .desktop-buttons-container {
        display: flex;
    }
}